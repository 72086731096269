import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { SeoProperties } from './interfaces'

const SEO: FC<SeoProperties> = ({
  lang = 'en',
  meta = [],
  data = { seo: { seo_title: 'Home' } },
}) => {
  const {
    author,
    ogImage,
    seo: {
      focus_synonyms: keywords,
      meta_description: metaDescription,
      seo_title: title,
    },
  } = data

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title="<Site name>"
      titleTemplate={title ? `${title} | %s` : '<Site name>'}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords?.join(','),
        },
        {
          property: `og:title`,
          content: title || '<Site name>',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords?.join(','),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          ogImage
            ? [
                {
                  property: 'og:image',
                  content: ogImage?.asset?.fluid?.src,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ]
        )
        .concat(meta)}
    />
  )
}

export default SEO
